<template>
  <div class="flex flex-wrap w-full min-h-screen">
    <div class="grid grid-cols-2 w-full">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex justify-center" style="width: 40%">
          <img alt="logo" src="@/assets/images/sme_logo.png" />
        </div>
        <div class="flex flex-col gap-2" style="width: 60%">
          <h3 class="text-2xl text-center mt-4 mb-4">Can't log in?</h3>
          <span class="text-center">We'll send a recovery link to</span>
          <div class="field">
            <InputText class="w-full" type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="Email address" />
            <span class="block text-red-500 italic text-left">{{ errors.first('email') }}</span>
          </div>
          <Button label="Send Recovery Link" class="w-full p-button" @click.prevent="signIn" />
          <span class="text-center">
            Check your inbox! We've sent a password recovery link to your email address. Follow the instruction in your email to reset the password. If you dont see our email,
            please check your spam or junk folder
          </span>
          <Divider />
          <Button label="Return to Log In" class="w-full p-button p-button-link" />
        </div>
      </div>
      <div class="flex flex-col items-center justify-center border-l border-black">
        <img alt="forgot_password" src="@/assets/images/forgot-password.jpg" style="width: 80%" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Divider from 'primevue/divider';

export default {
  components: {
    Button,
    InputText,
    Divider,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
};
</script>
